import { SystemProps, Flex } from '@storyofams/react-ui';
import { Button, Heading, Text } from '~components';

type FollowUsBlockProps = {
  content: {
    _uid?: string,
    title?: string,
    body_text?: string,
    social_links?:  Array<{
      _uid?: string,
      image?: {
        filename?: string,
        alt?: string,
      },
      link?: {
        cached_url?: string,
      };
    }>;
  };
  customProps?: any;
} & SystemProps;

export const FollowUsBlock = ({ content, customProps }: FollowUsBlockProps) => {
  let localCustomFontProps = {} as any;

  if (!!customProps?.color) {
    localCustomFontProps.color = customProps?.color
  }
  return (
    <Flex
      flexDirection={'column'}
      whiteSpace={'nowrap'}
      width={['100%', 'min-content']}
    >
      {!!content?.title
        ? (
          <Heading
            as="h3"
            width="100%"
            variant="sh3"
            fontSize={2.5}
            lineHeight={'150%'}
            fontWeight="800"
            {...localCustomFontProps}
          >{content?.title}</Heading>
        ) : null
      }
      {!!content?.body_text
        ? <Text
            mt={[2, 5]}
            fontSize={2}
            lineHeight={'150%'}
            fontWeight="600"
            {...localCustomFontProps}
          >{ content?.body_text }</Text>
        : null
      }
      {!!content?.social_links && content?.social_links.length && (
        <Flex mt={2} gap={'19px'}>
          {content?.social_links.map(sl => {
            return (
              <Button
                key={sl?._uid}
                variant="link"
                to={sl.link.cached_url}
                {...localCustomFontProps}
              >
                <Flex maxWidth={'27px'} px={0}>
                  <img width={'100%'} src={sl.image?.filename} />
                </Flex>
              </Button>
            )
          })}
        </Flex>
      )}
    </Flex>
  );
};
